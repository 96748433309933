import React from 'react';
import '../../../src/styles/product.css';
import { Button, List } from 'semantic-ui-react'
import { Course } from 'src/logic/landing/LandingPageStructure';
import { Bundle } from 'src/logic/models/bundle';
import { navigateToCheckout } from 'src/logic/checkout/utils';
import { ProductType } from 'src/logic/models/common';
import styled from 'styled-components';
import { AppHeader } from '../common/Header';
import { ProductDetails, ProductIdToDetailsMap } from './ProjectDetails';

const ProductSidebar = ({
    course,
    bundles
}: {
    course: Course;
    bundles: Array<Bundle>;
}) => {
    const productDetails = ProductIdToDetailsMap[course.course_id];

    return <div className='project-info-sidebar'>
        {/* <h1 className='product-title'> {course.details.name}</h1>
        <p className='product-description'>{course.details.description}</p>
        {course.discount && <div className='discount-tag'>{`Save $${course.discount}`}</div>}
        <br />
        Price:&nbsp;{course.discount && (<span className='green'>${course.price - course.discount}&nbsp;&nbsp;</span>)}
        {<span className={course.discount && 'crossed'}>${course.price}</span>}
        <br />
        <Button style={{ marginTop: '20px', marginBottom: '20px' }} onClick={() => {
            navigateToCheckout({ productId: course.course_id, type: ProductType.Product });
        }}>
            Book Now
        </Button> */}
        <CourseInfo course={course} />
        {bundles.length > 0 &&
            (<StyledBundleContainer color={productDetails.color}>
                <BundleContainerHeader>Add with {course.details.name}</BundleContainerHeader>
                {bundles.map((bundle, idx) => <>
                    <BundleContainer bundle={bundle} showItemList={true} />
                    {idx !== bundles.length - 1 && <hr />}
                </>)}
            </StyledBundleContainer>)}

    </div>

}

export const CourseInfo = ({
    course
}: {
    course: Course;
}) => {
    const productDetails = ProductIdToDetailsMap[course.course_id];

    return <CourseInfoContainer>
        <div>
            <p className='course-name'> {course.details.name}</p>
            {course.discount && <div className='discount-tag'>{`Save $${course.discount}`}</div>}
            <br />
        </div>
        <ButtonAndPriceContainer>
            <span>Price:&nbsp;{course.discount && (<span className='green'>${course.price - course.discount}&nbsp;&nbsp;</span>)}
                {<span className={course.discount && 'crossed'}>${course.price}</span>}
            </span>
            <BookNowButton onClick={() => {
                navigateToCheckout({ productId: course.course_id, type: ProductType.Product });
            }}>
                {`Add ${productDetails.shortName}`}
            </BookNowButton>
        </ButtonAndPriceContainer>
    </CourseInfoContainer>
}



export const BundleContainer = (
    { bundle, disableButton, showItemList }:
        { bundle: Bundle, disableButton?: boolean; showItemList?: boolean; }) => {

    const totalPrice = bundle.courses.reduce((acc, course) => acc + course.price, 0);;
    const totalDiscount = bundle.courses.reduce((acc, course) => acc + course.discount, bundle.discount);;
    return (<BundleInfoContainer>
        <div>
            <List style={{ display: showItemList ? 'block' : 'none' }}>
                {bundle.courses.map(course =>
                    <List.Item className="bundle-list-item" style={{ fontSize: '16px' }}>
                        ✅{' '}
                        {course.productName}
                    </List.Item>
                )}
            </List>
            <p>
                <br />Total Price:&nbsp;
                <span className='green'>${totalPrice - totalDiscount}</span>
                &nbsp;&nbsp;
                <span className='crossed'>${totalPrice}</span>
            </p>
        </div>
        <ButtonContainer>
            {!disableButton && <Button
                style={{ marginBottom: '10px' }}
                className='green-gradient'
                onClick={() => {
                    navigateToCheckout({
                        productId: bundle.bundle_id,
                        type: ProductType.Bundle
                    })
                }}
            >
                Add
            </Button>}
        </ButtonContainer>
    </BundleInfoContainer>);

};

export const CourseContainer = ({ course }: { course: Course }) => {
    const productDetails: ProductDetails = ProductIdToDetailsMap[course.course_id];
    return (
        <div className='bundle-container'>
            <img className="product-bundle-image" src={productDetails.thumbnailImage} alt="" />
            <p>
                <br />Total Price:&nbsp;
                <span className='green'>${course.price - (course.discount ?? 0)}</span>
                &nbsp;&nbsp;
                {course.discount && <span className='crossed'>${course.price}</span>}
            </p>
        </div>
    );

}

export const AuthBundleInfoContainer = ({ bundle }: {
    bundle: Bundle;
}) => {
    let total = 0;
    let totalDiscount = 0;
    return <>
        <div className='auth-bundle-container'>
            {bundle.courses.map((course) => {
                const productDetails: ProductDetails = ProductIdToDetailsMap[course.courseId];
                total += course.price;
                totalDiscount += course.discount;
                return (<img className="product-bundle-image" src={productDetails.thumbnailImage} alt="" />);
            })
            }
        </div>
        <p>
            <br />Total Price:&nbsp;
            <span className='green'>${total - totalDiscount}</span>
            &nbsp;&nbsp;
            {totalDiscount > 0 && <span className='crossed'>${total}</span>}
        </p>
    </>
}

export const ProductPageView = (props: {
    course: Course,
    bundles: Array<Bundle>
}) => {

    const productDetails = ProductIdToDetailsMap[props.course.course_id];

    if (!productDetails) return null;

    return <div>
        <div className='header'>
            <AppHeader />
        </div>
        <div className='product-page-container'>
            <div className='product-info-container'>
                <div className='main-product-image-container'>
                    <img className='product-image' src={productDetails.productImage} alt="" />
                    <Divider />
                    <ProductDescription>
                        {productDetails.productDescription}
                    </ProductDescription>
                    <CourseFormat>
                        {productDetails.courseFormat}
                    </CourseFormat>
                </div>
                <ProductSidebar
                    course={props.course}
                    bundles={props.bundles}
                />
            </div>
        </div>
    </div>
}

const StyledBundleContainer = styled.div<{
    color: string;
}>`
            margin-bottom: 10px;
            margin-top: 56px;
            width: 604px;
            border-radius: 23px;
            background: #FEF7EE;
            padding: 20px;
            background: ${props => props.color};
            @media only screen and (max-width: 700px) {
                width: 100%;
            .bundle-list-item {
                white - space: nowrap !important;
        }
    }
            `;

const CourseInfoContainer = styled.div`
            width: 604px;
            border-radius: 23px;
            background: #F6F5F5;
            position: relative;
            padding: 20px;
            .course-name {
                color: #383838;
            font-size: 18px;
            font-weight: 600;
    }
            @media only screen and (max-width: 700px) {
                width: 100%;
            flex-direction: column;
    }
            `;

const BundleContainerHeader = styled.p`
            color: #3A3A3A;
            font-size: 16px;
            font-weight: 600;
            `;

const BundleInfoContainer = styled.div`
            display: flex;
            justify-content: space-between;
            @media only screen and (max-width: 700px) {
                flex-direction: column;
    }
            `;

const ButtonContainer = styled.div`
            display: flex;
            align-items: end;
            height: inherit;
            `;

const BookNowButton = styled(Button)`
            width: fit-content;
            margin-top: 20px;
            margin-bottom: 20px;
            max-width: 350px;
            `;

const ButtonAndPriceContainer = styled.div`
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media only screen and (max-width: 700px) {
                flex-direction: column;
            align-items: flex-start;
    }
            `;

const Divider = styled.div`
            width: 493px;
            height: 9px;
            flex-shrink: 0;
            border: 1px solid rgba(0, 0, 0, 0.00);
            background: #F9C86F;
            margin-top: 17px;
            @media only screen and (max-width: 700px) {
                width: 100%;
    }
            `;

const ProductDescription = styled.div`
            color: #3C4043;
            font-size: 20px;
            max-width: 493px;
            margin-top: 20px;
            `;

const CourseFormat = styled.div`
            margin-top: 10px;
            color: #3C4043;
            font-size: 14px;
            `;
