import styled from 'styled-components';
import { Button as SementicButton } from 'semantic-ui-react'

export const Button = styled(SementicButton)`
    color: white !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    margin-top: 20px !important;
    background: linear-gradient(360deg, #F69E88 0%, #FDCC75 67.44%) !important;
    border-radius: 15px !important;
`;