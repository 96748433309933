import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
    CardElement,
    Elements,
    useStripe,
    useElements
} from '@stripe/react-stripe-js';
import './CardSectionStyles.css';
import { Button, Checkbox, Dimmer, Form, List, Loader, Segment } from 'semantic-ui-react';
import { FetchPaymentIntentSuccessResponse } from 'src/logic/serverRequests/productRequest';
import { Course } from 'src/logic/landing/LandingPageStructure';
import { Bundle } from 'src/logic/models/bundle';
import { AuthBundleInfoContainer, BundleContainer, CourseContainer } from '../product/ProductPage';
import { RequestState } from 'src/logic/utility/fetchTypes';
import FetchUtility from 'src/logic/utility/fetchUtility';
import styled from 'styled-components';
import toast, { Toaster, useToaster } from 'react-hot-toast';
// const stripePromise = loadStripe('pk_test_OCzmUNYgC1pGe8AujHsnNWaW');

const stripePromise = loadStripe('pk_live_51NK3XwAi1aQTnJ13P4lAqFI0as14UnUiO95EFo0UiPWXWQeczLLUc6G2G2Tk4UAnL9MBi83WRKKsIGg1rMWia1hW001PpJZpuO');

type CheckoutFormProps = {
    handleFetchPaymentIntent: () => Promise<FetchPaymentIntentSuccessResponse>;
    handleFetchConfirmPayment: ({ paymentIntentId }: { paymentIntentId: string }) => void;
    handleSetPaymentFetchStatus: ({ fetchStatus }: { fetchStatus: RequestState }) => void;
    handleRecipientEmailChange: ({ recipientEmail }: { recipientEmail: string }) => void;
    handleGiftToggle: () => void;
    paymentFetchStatus: RequestState;
    course?: Course;
    bundle?: Bundle;
    isGift?: boolean;
    recipientEmail: string;
};


export const productCheckoutImage = () => {

};

export const CheckoutForm = (props: CheckoutFormProps) => {
    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const stripe = useStripe();
    const elements = useElements();
    const [isTermsOfServiceChecked, setIsTermsOfServiceChecked] = useState(false)

    const handleChange = async (event: any) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        setDisabled(event.empty);
        setError(event.error ? event.error.message : "");
    };

    const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();
        if (elements == null) {
            return;
        }
        props.handleSetPaymentFetchStatus({
            fetchStatus: FetchUtility.getDefaultFetchRequestState(true)
        });
        const { paymentIntentId, clientSecret } = await props.handleFetchPaymentIntent();
        const payload = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement)
            }
        });

        if (payload.error) {
            setError(`Payment failed ${payload.error.message}`);
            setProcessing(false);
            props.handleSetPaymentFetchStatus({
                fetchStatus: FetchUtility.createErrorRequestState('')
            });
        } else {
            await props.handleFetchConfirmPayment({ paymentIntentId });
            setError(null);
            setProcessing(false);
            setSucceeded(true);
            props.handleSetPaymentFetchStatus({
                fetchStatus: FetchUtility.createSuccessfulRequestState('')
            });
        }
    };

    const ProductDisplay = props.bundle ?
        <AuthBundleInfoContainer
            bundle={props.bundle}
        /> : props.course ?
            <CourseContainer course={props.course} /> : <></>
    const courseNames = props.bundle ?
        props.bundle.courses.map(course => course.productName) : props.course ?
            [props.course.details.name] : []

    const showCheckoutForm = !(props.paymentFetchStatus.success || props.paymentFetchStatus.error)
    return (
        <Segment style={{ width: '430px' }}>
            <Toaster/>
            <Dimmer active={props.paymentFetchStatus.processing} inverted>
                <Loader />
            </Dimmer>
            <h4> Order Information</h4>
            <List bulleted>
                {courseNames.map(name => <List.Item>{name}</List.Item>)}
            </List>
            {ProductDisplay}
            <hr />
            <Form onSubmit={isTermsOfServiceChecked ? handleSubmit : () => {
                toast.error('Please agree with the Terms of Use policy and Refund policy.')
            }} style={{ display: !showCheckoutForm ? 'none' : '' }}>
                <Checkbox
                    label='Send as a gift'
                    labelPosition='right'
                    checked={props.isGift}
                    onChange={props.handleGiftToggle}
                />
                {props.isGift && (
                    <Form.Field className='checkout-field' required>
                        <input placeholder='Gift Recipient Email' onChange={
                            (event) => props.handleRecipientEmailChange({
                                recipientEmail: event.target.value
                            })
                        } />
                    </Form.Field>)}
                <CardSection />
       
                    <Form.Field required>
                        <Checkbox
                            required
                            checked={isTermsOfServiceChecked}
                            onChange={() => setIsTermsOfServiceChecked(!isTermsOfServiceChecked)}
                        />
                        <TermsOfServiceLabel>
                            &nbsp; I agree to the&nbsp;
                            <a href="/termsofservice" target="_blank" rel="noreferrer">Terms of Use policy</a>
                            &nbsp;and&nbsp;
                            <a href="/refund" target="_blank" rel="noreferrer">Refund policy.</a>
                        </TermsOfServiceLabel>
                    </Form.Field>
                    <StyledCheckoutButton disabled={
                        !stripe
                        || props.paymentFetchStatus.processing
                        || props.paymentFetchStatus.success
                        }
                        type={"submit"}
                        className='green-gradient'
                        style={{ width: '100%' }}
                        $isDisabled={!isTermsOfServiceChecked}
                    >
                        Confirm order
                    </StyledCheckoutButton>
           
            </Form>
            {props.paymentFetchStatus.success &&
                <div>
                    <p>The payment has been processed successfully.</p>
                    <p>An email is sent to you with registration details.</p>
                    <StyledCheckoutButton
                        style={{ width: '100%', marginBottom: '20px' }}
                        className='green-gradient'
                        onClick={() => {
                            window.location.href = '/';
                        }}
                    >
                        Return to home page
                    </StyledCheckoutButton>
                </div>}
            {props.paymentFetchStatus.error &&
                <div>
                    <p>An error has occured proccessing the payment</p>
                </div>}
        </Segment>
    );
};

const StyledCheckoutButton = styled(Button)<{
    $isDisabled: boolean;
}>`
    cursor: ${({ $isDisabled }) => $isDisabled ? 'not-allowed' : 'pointer'} !important;
`;

const TermsOfServiceLabel = styled.span`
    font-size: 14px;
`;

type CheckoutFormContainerProps = {
    initializePage: () => void;
    handleFetchPaymentIntent: () => Promise<FetchPaymentIntentSuccessResponse>;
    handleFetchConfirmPayment: ({ paymentIntentId }: { paymentIntentId: string }) => void;
    handleSetPaymentFetchStatus: ({ fetchStatus }: { fetchStatus: RequestState }) => void;
    handleRecipientEmailChange: ({ recipientEmail }: { recipientEmail: string }) => void;
    handleGiftToggle: () => void;
    paymentFetchStatus: RequestState;
    course?: Course;
    bundle?: Bundle;
    isGift: boolean;
    recipientEmail: string;
}
export const CheckoutFormContainer = (props: CheckoutFormContainerProps) => {
    useEffect(() => {
        props.initializePage();
    }, []);
    return <div className='checkout-form-container'
        style={
            {
                display: 'flex',
                justifyContent: 'center',
                marginTop: '60px'
            }
        }
    >
        <div>
            <Elements stripe={stripePromise}>
                <CheckoutForm
                    course={props.course}
                    bundle={props.bundle}
                    handleFetchPaymentIntent={props.handleFetchPaymentIntent}
                    handleFetchConfirmPayment={props.handleFetchConfirmPayment}
                    handleSetPaymentFetchStatus={props.handleSetPaymentFetchStatus}
                    handleRecipientEmailChange={props.handleRecipientEmailChange}
                    paymentFetchStatus={props.paymentFetchStatus}
                    isGift={props.isGift}
                    handleGiftToggle={props.handleGiftToggle}
                    recipientEmail={props.recipientEmail}

                />
            </Elements>
        </div>
    </div>
};

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#aab7c4",
            },
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
};

function CardSection() {
    return (
        <label style={{ width: '400px' }}>
            Card details
            <CardElement options={CARD_ELEMENT_OPTIONS} />
        </label>
    );
};

export default CardSection;
