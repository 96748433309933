import React from "react";

//importing logic layers
import '../../../src/styles/landing/landing.css';
import { Course } from '../../logic/landing/LandingPageStructure';
import { Icon } from 'semantic-ui-react'
import ProductCard from '../product/ProductCard'; import { AppHeader } from '../common/Header';
import styled from 'styled-components';
;

interface LandingPageViewProps extends PageViewProps {
  handleFetchCourses: Function,
  courses: Array<Course>
}

class LandingPageView extends React.Component<LandingPageViewProps, GenericObject> {

  public constructor(props: LandingPageViewProps) {
    super(props);
  }

  componentDidMount() {
    this.props.handleFetchCourses();
  }

  render() {
    const isMobile = window.screen.width <= 700;

    return (
      <>
        <div>
          {/* <Menu borderless fixed="top" className="header">
            <Menu.Item>
              <img alt="logo" id="site_logo" className="logo" src="/img/logo.png" />
            </Menu.Item>
          </Menu> */}
          <AppHeader />
        </div>
        <HeaderContainer>
          <HeaderContent>
            <HeaderTextContainer>
              <HeaderMainText>
                Spreading Smiles,<br /> One Face at a Time!
              </HeaderMainText>
              <HeaderDescription>
                Enjoy a transformative, life-changing experience with <br />
                an authentic meditations that work from day 1. <br /><br />
                Do it yourself or Gift to a loved one <br />
                <ExploreNowLink />
              </HeaderDescription>
            </HeaderTextContainer>
            {window.screen.width <= 700 ? (<HeaderImage src={'/img/SKY-Breath-Male-Crped_2.png'} alt="header" />) : <HeaderImage src={"/img/SKY-Breath-Male-Crped_1.png"} alt="header" />}
          </HeaderContent>
        </HeaderContainer>
        <PageContainer className="page-container">
          <div className='page-content'>
            <SectionHeader>
              Experience New Levels of <br />
              Calm, Clarity and Focus
            </SectionHeader>

            <div className='benefits-container'>
              <BenefitsItem>
                <BenefitsImage src="/img/energy.png" alt="get more energy" />
                <h2>Get More Energy</h2>
                <p>
                  To do more things that you love
                </p>
              </BenefitsItem>
              <BenefitsItem>
                <BenefitsImage src="/img/sleep.png" alt="sleep better" />
                <h2>Sleep Better</h2>
                <p>
                  with 213% more deep sleep*
                </p>
              </BenefitsItem>
              <BenefitsItem>
                <BenefitsImage src="/img/productivity.png" alt="productivity" />
                <h2>Improved Productivity</h2>
                <p>
                  Success with a smile 😀
                </p>
              </BenefitsItem>
            </div>
            <SectionHeader>
              Every tool you need to be happier {!isMobile && (< br />)} and live a more fulfilling life.
              <p>
                Add meditation to your life <br />
                Experience the joy of living.
              </p>
            </SectionHeader>
            <div id="products" className='product-grid-container'>
              <div className="product-grid">
                {this.props.courses.filter((course: Course) => !course.details.isAdvanced).map(course => {
                  return <ProductCard
                    id={course.course_id}
                    isAdvanced={course.details.isAdvanced}
                    title={course.details.name}
                    description={course.details.description}
                    productImageUrl={course.details.imageUrl}
                    price={course.price}
                    discountPrice={course.discount}
                  />;
                })}
              </div>
            </div>
            <SectionHeader>
              What people say...
            </SectionHeader>
            {/* 
            <div className='testimonial-container'>
              <div className='testimonial-video-container'>
                <Embed
                  className='testimonial-video'
                  id='449442713'
                  source='vimeo'
                  placeholder='/img/James_Nestor_SKY.png'
                />
              </div>
              <TestimonialTextContainer>
                <div className='testimonial-description'>
                  "No matter what you eat, how much you exercise,<br />
                  how skinny or young or wise you are, none of it <br />
                  matters if <b>you're not breathing properly</b>."
                  <br /><br />
                  "...[SKY] is the most powerful technique I've <br /> learned."
                  <br /><br />
                  - James nestor <br />
                  <div className='grey-text'>
                    author of New York Times bestseller, <br />
                    Breath: The New Science of a Lost Art
                  </div>
                </div>
              </TestimonialTextContainer>
            </div> */}
            <div className='testimonial-card-container'>
              <div className='testimonial-card'>
                <div className='testimonial-text'>
                  <TestimonialHeader>
                    “I was much healthier right away”
                  </TestimonialHeader>
                  "I was very ill. The medication and the actual illness were very disturbing to the mind. The only thing that helped me other than allopathic medicine was pranayama. It was a complete change...I was much healthier right away. My mind became clear and I became more comfortable in my body. Also, people commented on my skin all the time, so I think it helped with anti-aging. That's a benefit."
                </div>
                <div className='testimonial-info'>
                  <img src="/img/testimonial3.png" className='testimonial-img' />
                  <div className='testimonial-name'>
                    Patricia <br />
                    Retired ESL teacher
                  </div>
                </div>
              </div>
              <div className='testimonial-card'>
                <div className='testimonial-text'>
                  <TestimonialHeader>
                    “...I sleep at night”
                  </TestimonialHeader>
                  "It’s been night and day. On a completely practical level, I sleep at night. And I don’t have to take a handful of meds to do it."
                </div>
                <div className='testimonial-info'>
                  <img src="/img/testimonial2.png" className='testimonial-img' />
                  <div className='testimonial-name'>
                    David R. <br />
                    Veteran
                  </div>
                </div>
              </div>
              <div className='testimonial-card'>
                <div className='testimonial-text'>
                  <TestimonialHeader>
                    “Come to Work in a Better Mindset”
                  </TestimonialHeader>
                  "When I come to work (after doing my practices), having already centered myself, it's way easier to plan... delegate tasks to other people, or work with other people. Now I come to work in a much better mindset, and that in turn translates into the quality of work and the way I deal with people at work. Just the way I process emotions, thoughts, and feelings is different from before."
                </div>
                <div className='testimonial-info'>
                  <img src="/img/testimonial1.png" className='testimonial-img' />
                  <div className='testimonial-name'>
                    Dan J. <br />
                    Chef
                  </div>
                </div>
              </div>
            </div>
            <SectionHeader>
              100+ Independent Studies
              <p>
                conducted on four continents and published in peer review journals, including Yale and <br />
                Harvard, have demonstrated a comprehensive range of benefits from practicing <br />
                SKY Breath Meditation and related breathing exercises taught on the SKY Breath Meditation course.
              </p>
            </SectionHeader>
            <IndependentStudiesContainer>
              <img src="/img/independent_studies.png" alt="independent studies" />
              <ResearchTextContainer>
                <ResearchText>
                  <div className="number">33%</div>
                  <ArrowIcon />
                  <div>
                    <p className="main">Immune Cell</p>
                    count increases in 6 weeks
                  </div>
                </ResearchText>
                <ResearchText>
                  <div className="number">21%</div>
                  <ArrowIcon />
                  <div>
                    <p className="main">Life Satisfaction</p>
                    increases within 1 week
                  </div>
                </ResearchText>
                <ResearchText>
                  <div className="number">56%</div>
                  <ArrowIcon reverse />
                  <div>
                    <p className="main">Stress hormones</p>
                    reduction in 2 weeks
                  </div>
                </ResearchText>
              </ResearchTextContainer>
            </IndependentStudiesContainer>
            <SectionHeader>
              <p style={{
                color: '#EF7859',
                textAlign: 'center',
                fontSize: '16px',
              }}>
                SKY Breath Meditation
              </p>
              A Gift to Humanity
            </SectionHeader>
            <GiftContainer>
              <GiftTextContainer>
                <p>
                  <h3>
                    Gurudev Sri Sri Ravi Shankar
                  </h3>
                  Humanitarian, spiritual leader and an ambassador of peace and human values.
                  <br />
                  <br />
                  Through his life and work, Gurudev has inspired millions around the world with a vision of a stress-free, violence-free world. He has designed programs that provide techniques and tools to live a deeper, more joyous life and has established nonprofit organizations that recognize the human identity beyond the boundaries of gender, race, nationality, and religion.
                  <SocialMediaContainer>
                    {/* TODO: add links redirects */}
                    <SocialMediaIcon link="https://www.instagram.com/srisriravishankar/" src="/img/social_media_Instagram.png" />
                    <SocialMediaIcon link="https://open.spotify.com/artist/3PJTvWKCyMcCgZS3V5Wu7O" src="/img/social_media_Spotify.png" />
                    <SocialMediaIcon link="https://www.twitter.com/srisri" src="/img/social_media_tweeter.png" />
                    <SocialMediaIcon link="https://www.youtube.com/srisri" src="/img/social_media_Youtube.png" />
                  </SocialMediaContainer>
                </p>
              </GiftTextContainer>
              <img src="/img/wellness-retreat-gurudev-sri-sri-rav-shankar.png" alt="independent studies" />
            </GiftContainer>
          </div>
        </PageContainer>
      </>
    )
  }

}


const ExploreNowLink = () => {
  return (
    <div onClick={() => {
      document.getElementById('products').scrollIntoView();
    }}>
      <Link>Explore Our Meditations<Icon style={{ marginLeft: '5px' }} name='arrow right' /></Link>
    </div>
  )
}

const Link = styled.p`
font-size: 14px;
padding-left: 5px;
color: #1a73e8;
cursor: pointer;
width: fit-content;
margin-left: -5px;
&:hover {
  background: #e8f0fe;
}
`;

const IndependentStudiesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 40px;
  height: 607px;
  background: #FFF5E9;

  img {
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    height: 607px;
  }
`;

const SocialMediaContainer = styled.div`
  margin-top: 15px;
  display: flex;
  gap: 22px;
  cursor: pointer;
`;

const SocialMediaIcon = ({ link, src }: {
  link: string,
  src: string,
}) => {

  return (
    <StyledMediaIcon src={src} onClick={() => {
      window.open(link, '_blank');
    }} />
  )
}

const StyledMediaIcon = styled.img`
  height: 40px !important;
`;

const GiftContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 40px;
  height: 607px;
  background: #284256;
  color: white;
  margin-bottom: 100px;

  img {
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    height: 607px;
  }

  p {
    font-size: 16px;
    color: white;
    padding-left: 80px;
    padding-right: 80px;
    position: relative;
    top: 144px;
  }

  h3 {
    color: white;
    font-size: 24px;
    font-weight: 600;
  }
`;

const GiftTextContainer = styled.div`
`;

const HeaderContent = styled.div`
  display: inline-flex;
  padding-left: 0px;
  justify-content: flex-end;
  align-items: center;
  width: 1200px;
  height: 600px;
  border-radius: 40px;
  background: #FDF8F0;
`;

const HeaderMainText = styled.div`
  color: #3C4043;
  font-size: 36px;
  font-weight: 600;
`;

const HeaderDescription = styled.div`
  color: #000;
`;

const HeaderTextContainer = styled.div`
  white-space: nowrap;
  position: relative;
  left: 100px;
  ${Link} {
    font-size: 18px;
  }
`;

const HeaderImage = styled.img`
  border-radius: 40px;
`;

const SectionHeader = styled.div`
  color: #3C4043;
  text-align: center;
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 80px;
  margin-top: 20px;
  p {
    font-size: 18px;
    font-weight: 400;
    margin-top: 20px;
  }
`;

const BenefitsItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #3C4043;
  h2 {
    font-size: 20px;
    font-weight: 600;
  }
`;

const BenefitsImage = styled.img`
  height: 80px;
  width: 80px;
  margin-top: 15px;
  margin-bottom: 15px;
`;

const TestimonialHeader = styled.div`
  color: #3C4043;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 30px;
`;

const TestimonialTextContainer = styled.div`
  padding: 20px;
  padding-top: 30px;
  align-items: center;
  border-radius: 28px;
  border: 1px solid #FAEC00;
  background: #FFFCEA;
  width: 520px;
  display: flex;
  justify-content: center;
`;

const ResearchTextContainer = styled.div`
  display: flex;
  position: relative;
  left: 60px;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  gap: 50px;
  .number {
    color: #595959;
    font-size: 30px;
    font-weight: 700;
  }
  .main {
    color: #4A4A4A;
    font-size: 16px;
    font-weight: 600;
  }

`;

const ResearchText = styled.div`
  display: flex;
  gap: 10px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 100px;

  ${Link} {
    width: 100%;
    font-size: 16px;
  }

  @media only screen and (max-width: 700px) {
    padding: 15px;
    ${HeaderContent} {
      width: 100%;
      height: fit-content;
      border-radius: 0px;
      flex-direction: column-reverse;
      padding-bottom: 40px;
      border-top-right-radius: 40px;
      border-top-left-radius: 40px;
    }
  
    ${HeaderImage} {
      width: 100%;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  
    ${HeaderTextContainer} {
      position: static;
      white-space: initial;
      margin-top: 50px;
      text-align: center;
    }

    ${HeaderMainText} {
      font-size: 30px;
    }
    ${HeaderDescription} {
      font-size: 16px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
`;

const PageContainer = styled.div`
h1 {
  color: #EF9473;
  font-weight: 600;
}

@media only screen and (max-width: 700px) {
  padding: 15px;
  margin-top: 0px;
  ${TestimonialTextContainer} {
    width: 100% !important;
  }

  ${GiftContainer} {
    flex-direction: column-reverse;
    width: 100% !important;
    margin-top: 300px;
    margin-bottom: 150px;
    img {
      width: 100%;
      height: auto;
      border-top-right-radius: 40px !important;
      border-top-left-radius: 40px !important;
      border-bottom-left-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }

    p {
      padding-top: 40px;
      padding-left: 20px;
      padding-right: 20px;
      top: 0px;
    }
  }

  ${SocialMediaContainer} {
    padding-bottom: 20px;
    padding-top: 10px;
  }

  ${StyledMediaIcon} {
    width: 40px !important;
  }

  ${IndependentStudiesContainer} {
    flex-direction: column;
    width: 100% !important;
    img {
      width: 100% !important;
      height: auto !important;
      border-top-right-radius: 40px !important;
      border-top-left-radius: 40px !important;
      border-bottom-left-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }
  }

  ${ResearchTextContainer} {
    position: static !important;
    padding-left: 20px;
  }
`;

const ArrowIcon = ({
  reverse = false,
}) => <svg width="24" height="32" viewBox="0 0 24 32" fill="none" xmlns="http://www.w3.org/2000/svg" style={{
  transform: reverse ? 'rotate(180deg)' : 'rotate(0deg)',
}}>
    <path id="Arrow 1" d="M13.0607 0.939339C12.4749 0.353552 11.5251 0.353552 10.9393 0.939339L1.3934 10.4853C0.807611 11.0711 0.807611 12.0208 1.3934 12.6066C1.97919 13.1924 2.92893 13.1924 3.51472 12.6066L12 4.12132L20.4853 12.6066C21.0711 13.1924 22.0208 13.1924 22.6066 12.6066C23.1924 12.0208 23.1924 11.0711 22.6066 10.4853L13.0607 0.939339ZM13.5 32L13.5 2H10.5L10.5 32H13.5Z" fill="#53BE7E" />
  </svg>;


export default LandingPageView;
