import React from 'react';
import '../../../src/styles/landing/landing.css';
import { Label } from 'semantic-ui-react'
import styled from 'styled-components';
import { Button } from 'src/styles/common/styles';
import { ProductDetails, ProductIdToDetailsMap } from './ProjectDetails';

interface ProductCardProps {
    id: number,
    title: string,
    description: string,
    price: number,
    discountPrice?: number,
    tag?: string,
    productImageUrl: string,
    onAddToCartClickFunc?: Function,
    onLearnMoreClickFunc?: Function
    isAdvanced?: boolean
}

const ProductCard = (props: ProductCardProps) => {
    const productDetails: ProductDetails = ProductIdToDetailsMap[props.id]
    return (
        <ProductCardContainer color={productDetails.color}>
            <LevelTag>{productDetails.level}</LevelTag>
            <ProductImage style={{ backgroundImage: `url('${productDetails.thumbnailImage}')` }}>
                {props.discountPrice && <Label color='green' className="price-ribbon" ribbon="right">
                    Save ${props.discountPrice}
                </Label>}
            </ProductImage>
            <ProductInfoContainer>
                {productDetails.isOnline && <OnlineTag>Online</OnlineTag>}
                <ProductName>
                    {productDetails.name}
                </ProductName>
                <ProductDescription>
                    {productDetails.description}
                </ProductDescription>
                <ProductPrice>
                    {props.discountPrice && <span className='discount-price'> ${props.price - props.discountPrice} CAD </span>}
                    <span className={props.discountPrice && 'slashed-text'}>${props.price}</span> CAD
                </ProductPrice>
                <AddProductButton onClick={() => {
                    window.location.href = `/product/${props.id}`;
                }}>Add {productDetails.shortName}</AddProductButton>
                <SecondaryText>
                   {productDetails.footerText}
                </SecondaryText>
            </ProductInfoContainer>
        </ProductCardContainer>
    )
}

const OnlineTag = styled.div`
    font-style: italic;
`;

const ProductCardContainer = styled.div<{
    color: string;
}>`
    position: relative;
    width: 374px;
    height: 608px;
    border-radius: 28px;
    background: ${({ color }) => color};
`;

const LevelTag = styled.div`
    position: absolute;
    top: 220px;
    right: 10px;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
    border: 1px solid black;
`;

const ProductInfoContainer = styled.div`
    position: relative;
    padding: 10px;
    padding-left: 20px;
    margin-top: 50px;
    height: 400px;
`;

const ProductImage = styled.div`
    height: 200px;
    width: 100%;
    border-radius: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-size: cover;
    background-position: center;
`;

const AddProductButton = styled(Button)`
    width: 100%;
    position: absolute;
    top: 170px;
    left: 10px;
    max-width: 350px;
`;

const ProductPrice = styled.p`
    position: absolute;
    top: 150px;
`;

const ProductName = styled.p`
  font-size: 18px;
  font-weight: 600;
`;

const ProductDescription = styled.p`
    font-size: 14px
`;

const SecondaryText = styled.p`
    color: #3A3A3A;
    font-size: 14px;
    font-style: italic;
    position: absolute;
    bottom: 90px;
    left: 20px;
    max-width: 344px;
`;

export default ProductCard;
